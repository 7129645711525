import { Link, COLORS, Spread, Stack, Tag, Typography, TypographyVariant, Button, Spacer, Block, Card, Divider, StatefulTooltip, } from '@humanfirst/elektron';
import { EvidenceResource, ToolResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import React, { useMemo, useState } from 'react';
import { Else, If, Then, When } from 'react-if';
import { BetaTag } from 'src/components/Badges';
import { PieChart } from 'src/components/Charts';
import { IfToggle } from 'src/components/IfToggle';
import { LoadingState } from 'src/components/LoadingState';
import { PATHS } from 'src/config/path';
import { useSearchWithFacets } from 'src/hooks/search';
import { generatePath } from 'src/utils/path';
import { deserializeQuery } from 'src/utils/query';
import { scopeToFilterValues } from 'src/utils/workspaceScope';
const COLUMN_SIZE = '248px 248px';
const COLUMN_GAP = '24px';
const MAX_LEGEND_TEXT_WIDTH = 12;
const NoResult = () => {
    return (React.createElement(Block, { height: "100%", display: "flex", alignItems: "center" },
        React.createElement(Typography, { variant: TypographyVariant.Heading2, color: COLORS.gray1 }, "No results")));
};
export const TechTypeInsights = ({ insights }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const topTechType = useMemo(() => {
        var _a, _b, _c;
        const typeFacet = (_c = (_b = (_a = insights.facets.filter((x) => x.field === 'technologyType')) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.facets) !== null && _c !== void 0 ? _c : [];
        return {
            software: typeFacet.find((x) => x.facet === 'Software-Based Component'),
            physical: typeFacet.find((x) => x.facet === 'Physical Sensor'),
        };
    }, [insights.facets]);
    const physicalDeviceCount = (_c = (_b = (_a = topTechType.physical) === null || _a === void 0 ? void 0 : _a.count) === null || _b === void 0 ? void 0 : _b.toLocaleString()) !== null && _c !== void 0 ? _c : '0';
    const softwareCount = (_f = (_e = (_d = topTechType.software) === null || _d === void 0 ? void 0 : _d.count) === null || _e === void 0 ? void 0 : _e.toLocaleString()) !== null && _f !== void 0 ? _f : '0';
    return (React.createElement(Stack, null,
        React.createElement(Block, null,
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Details }, "Technology Type"),
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Label }, "by Technology Count")),
        React.createElement(Stack, null,
            React.createElement(If, { condition: physicalDeviceCount === '0' && softwareCount === '0' },
                React.createElement(Then, null,
                    React.createElement(NoResult, null)),
                React.createElement(Else, null,
                    React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                        React.createElement(Tag, { closeable: false },
                            React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                                React.createElement(Typography, { singleLine: true, variant: TypographyVariant.Details }, "Physical Sensor"))),
                        React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, (_j = (_h = (_g = topTechType.physical) === null || _g === void 0 ? void 0 : _g.count) === null || _h === void 0 ? void 0 : _h.toLocaleString()) !== null && _j !== void 0 ? _j : '0')),
                    React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                        React.createElement(Tag, { closeable: false },
                            React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                                React.createElement(Typography, { singleLine: true, variant: TypographyVariant.Details }, "Software Based Component"))),
                        React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, (_m = (_l = (_k = topTechType.software) === null || _k === void 0 ? void 0 : _k.count) === null || _l === void 0 ? void 0 : _l.toLocaleString()) !== null && _m !== void 0 ? _m : '0')))))));
};
export const TechVendorInsights = ({ insights }) => {
    const topVendors = useMemo(() => {
        var _a, _b, _c;
        const makerFacet = (_c = (_b = (_a = insights.facets.filter((x) => x.field === 'maker')) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.facets) !== null && _c !== void 0 ? _c : [];
        return makerFacet.slice(0, 3);
    }, [insights.facets]);
    return (React.createElement(Stack, null,
        React.createElement(Block, null,
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Details }, "Top Vendors"),
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Label }, "by Technology Count")),
        React.createElement(Stack, null,
            React.createElement(If, { condition: topVendors.length > 0 },
                React.createElement(Then, null, topVendors.map((x) => {
                    return (React.createElement(Stack, { key: x.facet, direction: 'horizontal', alignItems: 'center' },
                        React.createElement(Tag, { closeable: false },
                            React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                                React.createElement(Typography, { singleLine: true, variant: TypographyVariant.Details }, x.facet))),
                        React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, x.count)));
                })),
                React.createElement(Else, null,
                    React.createElement(NoResult, null))))));
};
const TechnologyInsights = ({ scopes }) => {
    const techInsights = useSearchWithFacets({
        searchType: 'Technology',
        filters: scopes,
    });
    const { data: totalTech, isLoading: isTechLoading } = useElektraApi(ToolResource.getStatistics());
    if (isTechLoading || techInsights.isLoading) {
        return React.createElement(LoadingState, null);
    }
    return (React.createElement(Stack, null,
        React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Technologies"),
        React.createElement(Stack, { gap: '48px', direction: 'horizontal' },
            React.createElement(Block, null,
                React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, "Matching Research Criteria"),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, techInsights.hitCount.toLocaleString()),
                React.createElement(Spacer, null),
                React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, "Total in Atlas"),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, totalTech === null || totalTech === void 0 ? void 0 : totalTech.count.toLocaleString())),
            React.createElement(TechVendorInsights, { insights: techInsights }),
            React.createElement(TechTypeInsights, { insights: techInsights }))));
};
const getWidthValues = (data) => {
    const chartWidth = 640;
    const containerWidth = 220;
    const maxCountLength = data.reduce((max, x) => {
        const textCount = x.y.toString().length + x.x.length;
        max = textCount > max ? textCount : max;
        return max;
    }, 0);
    if (maxCountLength > MAX_LEGEND_TEXT_WIDTH) {
        const padding = (maxCountLength - MAX_LEGEND_TEXT_WIDTH) * 10;
        // Turns out that the padding needs to be scaled by a ratio of the chart width to the container width
        // This scaling problem becomes more obvious at larger count values
        return [
            chartWidth + (chartWidth / containerWidth) * padding,
            containerWidth + padding,
        ];
    }
    return [chartWidth, containerWidth];
};
export const V3Insights = ({ insights }) => {
    var _a, _b, _c, _d;
    const byV3ChartData = useMemo(() => {
        var _a, _b, _c;
        const v3Facet = (_c = (_b = (_a = insights.facets.filter((x) => x.field === 'v3')) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.facets) !== null && _c !== void 0 ? _c : [];
        return v3Facet
            .sort((a, b) => a.facet.localeCompare(b.facet)) // Sort by facet (title) in ascending order
            .map(({ facet, count }) => ({
            y: count,
            x: facet.replace(' Validation', ''),
        }));
    }, [insights.facets]);
    const dtUseContextFacet = (_b = (_a = insights.facets.find((x) => x.field === 'dhtUseContext')) === null || _a === void 0 ? void 0 : _a.facets) !== null && _b !== void 0 ? _b : [];
    const validationEvidenceCount = (_d = (_c = dtUseContextFacet.find((x) => x.facet === 'Validation')) === null || _c === void 0 ? void 0 : _c.count) !== null && _d !== void 0 ? _d : 0;
    const [chartWidth, containerWidth] = useMemo(() => {
        return getWidthValues(byV3ChartData);
    }, [byV3ChartData]);
    return (React.createElement(Stack, null,
        React.createElement(Block, { "data-testid": "v3-insights", height: "100%", width: `${containerWidth}px` },
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Details }, "Validation Evidence"),
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Label }, "by Validation Type"),
            React.createElement(If, { condition: !validationEvidenceCount },
                React.createElement(Then, null,
                    React.createElement(NoResult, null)),
                React.createElement(Else, null,
                    React.createElement(PieChart, { data: byV3ChartData, chartWidth: chartWidth, donut: true, totalLabel: "Evidence", totalDataCount: validationEvidenceCount }))))));
};
export const CTPhaseInsights = ({ insights }) => {
    var _a, _b, _c, _d;
    const ctPhaseChartData = useMemo(() => {
        var _a, _b, _c;
        const ctPhaseFacet = (_c = (_b = (_a = insights.facets.filter((x) => x.field === 'ctPhases')) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.facets) !== null && _c !== void 0 ? _c : [];
        return ctPhaseFacet
            .sort((a, b) => a.facet.localeCompare(b.facet)) // Sort by facet (title) in ascending order
            .map(({ facet, count }) => ({ y: count, x: facet }));
    }, [insights.facets]);
    const ctData = (_b = (_a = insights.facets.find((x) => x.field === 'typeOfSource')) === null || _a === void 0 ? void 0 : _a.facets) !== null && _b !== void 0 ? _b : [];
    const totalCTCount = (_d = (_c = ctData.find((f) => f.facet === 'ClinicalTrials.gov')) === null || _c === void 0 ? void 0 : _c.count) !== null && _d !== void 0 ? _d : 0;
    const [chartWidth, containerWidth] = useMemo(() => {
        return getWidthValues(ctPhaseChartData);
    }, [ctPhaseChartData]);
    return (React.createElement(Stack, null,
        React.createElement(Block, { height: '100%', width: `${containerWidth}px` },
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Details }, "Clinical Trials"),
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Label }, "by Trial Phase"),
            React.createElement(If, { condition: !totalCTCount },
                React.createElement(Then, null,
                    React.createElement(NoResult, null)),
                React.createElement(Else, null,
                    React.createElement(PieChart, { data: ctPhaseChartData, chartWidth: chartWidth, donut: true, totalLabel: totalCTCount === 1 ? 'Trial' : 'Trials', totalDataCount: totalCTCount }))))));
};
const EvidenceInsights = ({ scopes }) => {
    const evidenceInsights = useSearchWithFacets({
        searchType: 'Evidence',
        filters: scopes,
    });
    const { data: totalEvidence, isLoading: isEvidenceLoading } = useElektraApi(EvidenceResource.getStatistics());
    if (isEvidenceLoading || evidenceInsights.isLoading) {
        return React.createElement(LoadingState, null);
    }
    return (React.createElement(Stack, null,
        React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Evidence"),
        React.createElement(Stack, { gap: '48px', direction: 'horizontal' },
            React.createElement(Block, null,
                React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, "Matching Research Criteria"),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, evidenceInsights.hitCount.toLocaleString()),
                React.createElement(Spacer, null),
                React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, "Total in Atlas"),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, totalEvidence === null || totalEvidence === void 0 ? void 0 : totalEvidence.count.toLocaleString())),
            React.createElement(Block, { display: "grid", gridTemplateColumns: COLUMN_SIZE, gridColumnGap: COLUMN_GAP },
                React.createElement(V3Insights, { "data-testid": "v3-insights", insights: evidenceInsights }),
                React.createElement(CTPhaseInsights, { insights: evidenceInsights })))));
};
const ResearchCriteriaInsights = ({ scopes }) => {
    return (React.createElement(Card, { variant: "tertiary" },
        React.createElement(Stack, { direction: 'horizontal' },
            React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Overview of Data by Research Criteria"),
            React.createElement(BetaTag, null)),
        React.createElement(Spacer, { size: '12px' }),
        React.createElement(Stack, { gap: '24px' },
            React.createElement(TechnologyInsights, { scopes: scopes !== null && scopes !== void 0 ? scopes : [] }),
            React.createElement(Divider, null),
            React.createElement(EvidenceInsights, { scopes: scopes !== null && scopes !== void 0 ? scopes : [] }))));
};
const CurrentResearchCriteria = ({ workspace, }) => {
    var _a;
    const scopeValues = scopeToFilterValues((_a = workspace.scope) !== null && _a !== void 0 ? _a : '');
    const editUrl = generatePath(PATHS.projects.edit, {
        projectId: workspace.id,
    });
    return (React.createElement(Spread, { gap: "24px", direction: "horizontal", alignItems: "start" }, Object.entries(scopeValues).map(([key, values]) => (React.createElement(Stack, { key: key, direction: "vertical", gap: "16px", alignItems: "start", flex: "1 1", width: '100%' },
        React.createElement(Typography, { variant: TypographyVariant.Body, color: COLORS.gray1, singleLine: true }, key),
        React.createElement(When, { condition: values.length === 0 },
            React.createElement(Link, { href: editUrl }, "Set Criteria")),
        values.map((value) => (React.createElement(Tag, { key: value, variant: "outlined", color: "transparent", closeable: false, kind: "neutral", maxWidth: "auto" },
            React.createElement(Typography, { singleLine: true, variant: TypographyVariant.Body, color: COLORS.black }, value)))))))));
};
export const ProjectResearchCriteria = ({ workspace, }) => {
    const workspaceScopeFlters = useMemo(() => {
        var _a, _b;
        return (_b = deserializeQuery((_a = workspace.scope) !== null && _a !== void 0 ? _a : '').filters) !== null && _b !== void 0 ? _b : [];
    }, [workspace.scope]);
    const [isOpen, setIsOpen] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { gap: "16px", alignSelf: 'start' },
            React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                React.createElement(Typography, { variant: TypographyVariant.Heading3Bold, marginBottom: '0px' }, "Research Criteria"),
                React.createElement(IfToggle, { name: "project-research-insights" },
                    React.createElement(If, { condition: workspace.scope },
                        React.createElement(Then, null,
                            React.createElement(Button, { variant: "minimal", onClick: () => setIsOpen(!isOpen) },
                                isOpen ? 'Hide' : 'View',
                                " Data Overview",
                                React.createElement(Spacer, { size: "4px" }),
                                React.createElement(BetaTag, null))),
                        React.createElement(Else, null,
                            React.createElement(StatefulTooltip, { triggerType: "hover", placement: "bottomRight", showArrow: true, content: React.createElement(Block, { width: '160px' },
                                    React.createElement(Typography, { align: 'center' }, "'Apply research criteria to enable Data Overview'")) },
                                React.createElement(Button, { variant: "minimal", disabled: true },
                                    "View Data Overview",
                                    React.createElement(Spacer, { size: "4px" }),
                                    React.createElement(BetaTag, null))))))),
            React.createElement(CurrentResearchCriteria, { workspace: workspace })),
        React.createElement(IfToggle, { name: "project-research-insights" },
            React.createElement(When, { condition: isOpen },
                React.createElement(Block, { maxWidth: '800px' },
                    React.createElement(Spacer, null),
                    React.createElement(ResearchCriteriaInsights, { scopes: workspaceScopeFlters }))))));
};
